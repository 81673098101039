import React from 'react'
import {graphql, Link} from 'gatsby'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import SanityImage from 'gatsby-plugin-sanity-image'
import { getReviewUrl,
 } from '../lib/helpers'
 import Tilt from 'react-parallax-tilt'
 import tw, { theme, css } from 'twin.macro'
 import { format } from 'date-fns'
 import Rating from '../components/rating'
 import RatingHollow from '../components/rating-hollow'

// Add “reviews” to the GraphQL query
export const query = graphql`
  query CategoryReviewTemplateQuery($id: String!) {
    posts: allSanityReview(
      filter: {
        categories: { elemMatch: { id: { eq: $id } } }
        slug: { current: { ne: null } }
        publishedAt: { ne: null }
      }
      sort: { fields: [publishedAt], order: DESC }
    ) {
      edges {
        node {
          id
          rating
          mainImage {
            ...ImageWithPreview
            caption
            alt
          }
          publishedAt
          slug {
            current
          }
          title
        }
      }
    }
    category: sanityCategoryReview(id: { eq: $id }) {
      title
      description
    }
  }
`

const CategoryReviewTemplate = props => {
  const {data = {}, errors} = props
  // Destructure the new reviews property from props
  const {title, description, reviews } = data.category || {}
  const posts = data && data.posts.edges
  
  return (
    <Layout>
      {errors && <GraphQLErrorList errors={errors} />}
      {!data.category && <p>No category data</p>}
      <SEO title={title + ` Reviews`} description={description} />
      <section tw="w-full">
        <div
          tw="mt-5 pt-5 md:mt-16 md:pt-20 mx-6 md:mx-auto grid grid-cols-1 md:grid-cols-2 font-serif pb-10 md:pb-16 border-b"
          css={{
            width: 'min(1024px, calc(100% - 48px))',
            gridRowGap: 8,
            borderColor: `${theme`borderColor.accent`}`,
          }}
        >
          <h1 tw="text-3xl lg:text-4xl font-semibold col-span-1 capitalize">{title}</h1>
          <p tw="col-span-1 text-softer prose prose-lg xl:prose-xl">{description}</p>
        </div>
      </section>
      <main
        tw="mb-24 md:mb-32"
        css={{
          display: 'grid',
          gridTemplateColumns: '1fr min(1024px, calc(100% - 48px)) 1fr',
          gridRowGap: 8,
          '& > *': {
            gridColumn: 2,
          },
        }}
      >
        <section tw="mt-10 md:mt-24 font-serif">
          <div tw="pb-8">
            <div tw="grid grid-cols-1 md:grid-cols-3 md:gap-x-8 gap-y-8">
              {posts && (
                <React.Fragment>
                  {posts.map(review => (
                    <Link to={getReviewUrl(review.node.slug.current)}>
                      <div
                        tw="rounded flex flex-row-reverse md:flex-col items-center"
                        className="group"
                      >
                        <div tw="top-0 h-32 md:h-56 w-32 md:w-full">
                          {review.node.mainImage && !review.node.mainImage.asset._id.includes('png') && (
                            <Tilt
                              tiltReverse={true}
                              tiltMaxAngleX="9.0"
                              tiltMaxAngleY="9.0"
                              gyroscope={true}
                              css={{ height: 'inherit' }}
                            >
                              <SanityImage
                                {...review.node.mainImage}
                                tw="scale-90 max-h-full object-contain m-auto shadow-sm border rounded w-full bg-white"
                              />
                            </Tilt>
                          )}
                          {review.node.mainImage && review.node.mainImage.asset._id.includes('png') && (
                            <SanityImage
                              {...review.node.mainImage}
                              tw="transform transition duration-150 ease-in-out scale-75 md:scale-90 motion-safe:group-hover:scale-90 md:motion-safe:group-hover:scale-100 -rotate-6 group-hover:rotate-0 max-h-full object-cover"
                              css={{
                                margin: '0 auto !important',
                                '&[data-lqip]': {
                                  objectFit: 'cover',
                                },
                              }}
                            />
                          )}
                        </div>
                        <div tw="flex-grow md:p-4 md:text-center space-y-1">
                          <h2 tw="text-2xl md:text-3xl font-medium">{review.node.title}</h2>
                          {review.node.rating && (
                            <figure>
                              <div tw="inline-flex space-x-1">
                                <Rating rating={review.node.rating} />
                                <RatingHollow rating={review.node.rating} />
                              </div>
                              <figcaption tw="font-sans sr-only">
                                Rating: {review.node.rating} out of 5
                              </figcaption>
                            </figure>
                          )}
                          <div tw="font-sans text-sm text-softer opacity-70 mt-1">
                            {format(new Date(review.node.publishedAt), 'do MMM yyyy')}
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))}
                </React.Fragment>
              )}
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}

export default CategoryReviewTemplate